<template>
  <div
    class="horizontal-layout self__registration"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'blue' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
      :class="{'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl'}"
    >
      <slot
        name="navbar"
      >
        <app-navbar-horizontal-layout-brand />
      </slot>
    </b-navbar>

    <div class="service_form_details">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              md="12"
              cols="12"
            >
              <h1 class="custom-header-title">
                {{ vendor.stringID }} - {{ vendor.companyName }}
              </h1>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-card
        class="mb-3 p-2 calllog-details serviceForm_details"
      >
        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <h3>Company General Information</h3>
          </b-col>
        </b-row>
        <b-table-simple
          borderless
          responsive
        >
          <b-tbody>
            <b-tr>
              <b-th width="230px">
                Registration No.
              </b-th>
              <b-td>{{ vendor.companyRegistration ? vendor.companyRegistration : '-' }}</b-td>
              <b-th width="230px">
                Tel
              </b-th>
              <b-td>{{ vendor.companyContact ? vendor.companyContact : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th width="230px">
                GST Registration No.
              </b-th>
              <b-td>{{ vendor.companyGSTRegistration ? vendor.companyGSTRegistration : '-' }}</b-td>
              <b-th width="230px">
                Fax
              </b-th>
              <b-td>{{ vendor.companyFax ? vendor.companyFax : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th width="230px">
                NRIC No. (For Individual)
              </b-th>
              <b-td>{{ vendor.individualNRIC ? vendor.individualNRIC : '-' }}</b-td>
              <b-th width="230px">
                Address
              </b-th>
              <b-td>{{ vendor.companyAddress ? vendor.companyAddress : '-' }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <hr class="dividerHR">
        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="9"
          >
            <h3>Sales Person Information</h3>
          </b-col>
        </b-row>
        <b-table-simple
          borderless
          responsive
        >
          <b-tbody>
            <b-tr>
              <b-th style="width:360px">
                Name
              </b-th>
              <b-td>{{ vendor.salesPersonName ? vendor.salesPersonName : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th style="width:360px">
                Contact No.
              </b-th>
              <b-td>{{ vendor.salesPersonContact ? vendor.salesPersonContact : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th style="width:360px">
                Email Address
              </b-th>
              <b-td>{{ vendor.salesPersonEmail ? vendor.salesPersonEmail : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th style="width:360px">
                Email Address 2
              </b-th>
              <b-td>{{ vendor.salesPersonEmail2 ? vendor.salesPersonEmail2 : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th style="width:360px">
                Any Related Family Parties Working In NV Group?
              </b-th>
              <b-td class="text-capitalize">
                {{ vendor.relativeInNirvana ? vendor.relativeInNirvana : '-' }}
                <br>
                <span v-if="vendor.relativeInNirvana == 'yes'"> {{ vendor.relativeDetail ? vendor.relativeDetail : '-' }}</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <hr class="dividerHR">

        <b-row
          class="content-header"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="9"
          >
            <h4>
              <span class="align-middle">Product / Service Provided and Payment Terms</span>
            </h4>
          </b-col>
        </b-row>
        <b-table-simple
          borderless
          responsive
        >
          <b-tbody>
            <b-tr>
              <b-th style="width:360px">
                Type of Product/Service Provided
              </b-th>
              <b-td>{{ vendor.providedServiceType ? vendor.providedServiceType : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th style="width:360px">
                Payment Terms
              </b-th>
              <b-td>{{ resolvePaymentTerms(vendor.paymentTerms) }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <hr class="dividerHR">

        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <h3>Banking Information</h3>
          </b-col>
        </b-row>
        <b-table-simple
          borderless
          responsive
        >
          <b-tbody>
            <b-tr>
              <b-th width="230px">
                Account Payee Name
              </b-th>
              <b-td>{{ vendor.accountPayeeName ? vendor.accountPayeeName : '-' }}</b-td>
              <b-th width="230px">
                Bank Account No.
              </b-th>
              <b-td>{{ vendor.bankAccountNo ? vendor.bankAccountNo : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th width="230px">
                Bank Name
              </b-th>
              <b-td>{{ vendor.bankName ? vendor.bankName : '-' }}</b-td>
              <b-th width="230px">
                Address
              </b-th>
              <b-td>{{ vendor.bankAddress ? vendor.bankAddress : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th width="230px">
                Bank Branch
              </b-th>
              <b-td>{{ vendor.bankBranch ? vendor.bankBranch : '-' }}</b-td>
              <b-th width="230px">
                Email Address
              </b-th>
              <b-td>{{ vendor.bankEmail ? vendor.bankEmail : '-' }}</b-td>
            </b-tr>
            <b-tr>
              <b-th width="230px">
                Swift Code (For Overseas)
              </b-th>
              <b-td>{{ vendor.swiftCode ? vendor.swiftCode : '-' }}</b-td>
              <b-th width="230px">
                Paynow Details
              </b-th>
              <b-td>{{ vendor.paynowNo ? vendor.paynowNo : '-' }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <hr class="dividerHR">

        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <h3>Certifications Obtained</h3>
          </b-col>
        </b-row>

        <b-table-simple
          borderless
          responsive
        >
          <b-tbody>
            <b-tr>
              <b-th width="230px">
                Certifications
              </b-th>
              <b-td
                class="certificate_col"
              >
                <span
                  v-for="(certificate, key) in vendor.certificates"
                  :key="key"
                  class="content-header-left mb-2"
                  cols="12"
                  md="4"
                >
                  <span v-if="certificate == 'Others'">
                    {{ vendor.otherCertificates }}
                  </span>
                  <span v-else>
                    {{ certificate }}
                  </span>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <hr class="dividerHR">

        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="9"
          >
            <h3>Attachments</h3>
          </b-col>
        </b-row>
        <b-row>
          <!-- Content Left -->
          <b-col
            v-for="(file, key) in vendor.attachments"
            :key="key"
            class="content-header-left mb-2"
            cols="12"
            md="4"
          >
            <div
              class="attachment-item"
            >
              <b-img
                v-if="file.type.includes('image')"
                left
                height="40"
                :src="require('@/assets/images/admin/doc_jpg.png')"
                alt="Left image"
              />
              <b-img
                v-if="file.type.includes('application')"
                left
                height="40"
                :src="require('@/assets/images/admin/doc_file.png')"
                alt="Left image"
              />
              <strong>
                <b-link
                  :href="file ? file.data : ''"
                  target="_blank"
                >
                  {{ file ? file.name : '' }}
                </b-link>
              </strong>
              <span>{{ file ? `${file.size}mb` : '' }}</span>
            </div>
            <div class="doc__desc">
              <span v-if="file.description">
                {{ file.description }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <div class="without__bg">
        <div class="signatories_block pt-2">
          <b-row class="content-header">
            <b-col
              v-for="(signatory, index) of vendor.signatories"
              :key="index"
              cols="3"
              md="3"
              class="mt-md-0 mt-3"
              order="2"
              order-md="1"
            >
              <b-card-text class="mb-0">
                <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                <div class="sign-box">
                  <div v-if="signatory.user">
                    <b-avatar
                      v-if="signatory.user.image"
                      :src="signatory.user.image"
                    />
                    <b-avatar
                      v-else
                      :text="resolveAcronymName(signatory.user.name)"
                    />
                  </div>
                  <div v-if="signatory.signed == true">
                    <!-- <div v-if="signatory.user && signatory.user.signature">
                        <b-img
                        class="mb-1 mb-sm-0 user__avatar"
                        height="50"
                        :src="signatory.user.signature"
                        />
                    </div> -->
                    <div v-if="signatory.user && signatory.user.name">
                      <span>{{ signatory.user.name }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <p>Pending Signature</p>
                  </div>
                </div>
                <p class="ml-75 semi-bold">
                  {{ signatory.user ? signatory.user.name : '' }}
                </p>
                <p class="ml-75">
                  {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                </p>
                <p class="ml-75">
                  {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                </p>
              </b-card-text>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>

    <!-- Footer -->
    <footer
      class="footer footer-light has_save_bar"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BRow, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg, BLink, BCardText,
  BAvatar, BNavbar,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useLayoutHorizontal from '@core/layouts/layout-horizontal/useLayoutHorizontal'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import Ripple from 'vue-ripple-directive'

import AppFooter from '../../../layouts/components/AppFooter.vue'
import Loader from '../../../layouts/horizontal/Loader.vue'
import AppNavbarHorizontalLayoutBrand from '../../../layouts/horizontal/AppNavbarHorizontalLayoutBrand.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BNavbar,
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BLink,
    BCardText,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppFooter,
    Loader,
    AppNavbarHorizontalLayoutBrand,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      vendor: {},
    }
  },
  created() {
    this.$http.get(`vendors/${this.$route.params.id}/information/frontend`)
      .then(response => {
        this.vendor = response.data.data || {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolvePaymentTerms(val) {
      let text = ''
      if (val === 'Others') {
        text = this.vendor.paymentTermsDescription
      } else {
        text = `${val}`
      }

      return text
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
